import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import QuestionMark from '../assets/img/question.png';
import Airplane from '../assets/img/airplane.png';
import Ship from '../assets/img/ship.png';
import Vehicle from '../assets/img/vehicle.png';
import Bycicle from '../assets/img/bycicle.png';
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { userActions } from "../_actions/user.actions";
import connect from 'react-redux/es/connect/connect';
import { scroller } from 'react-scroll';
import { LazyLoadImage } from "react-lazy-load-image-component";


class Prices extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <section className="position-relative dark-background">
                <div className="container-fluid">
                    <div className="row px-md-5 px-0">
                        <div className="col-md-5 prices-heading-div pl-4">
                            <h2>
                                <FormattedHTMLMessage id={ 'prices.heading' } defaultMessage={ 'How much web development cost?' } />
                            </h2>
                            <p>
                                <FormattedMessage id={ 'prices.text' } defaultMessage={ 'How much web development cost?' } />
                            </p>
                        </div>
                        <div className="col-md-7 text-center d-lg-block d-none">
                            <img src={ QuestionMark } alt="question mark" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center my-3">
                            <h4>
                                <FormattedMessage id={ 'prices.description' } defaultMessage={ 'How much web development cost?' } />
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center mb-3 mb-lg-0">
                            <div className="row white-background p-md-4 p-3">
                                <LazyLoadImage className="price-item-img" alt="Landing page" src={Bycicle} />
                                <div className="col-12 price-item-header text-center py-3">
                                    <FormattedHTMLMessage id={ 'prices.landing' } defaultMessage={ 'How much web development cost?' } />
                                </div>
                                <div className="col-12 price-number-div text-center mb-4">
                                    <FormattedMessage id={ 'prices.from' } defaultMessage={ 'How much web development cost?' } /> <span className="price-number">300 &euro;</span>
                                </div>
                                <div className="price-body text-left">
                                    <p>
                                        <FormattedMessage id={ 'prices.landing_text' } defaultMessage={ 'How much web development cost?' } />
                                    </p>
                                    <p><strong><FormattedMessage id={ 'prices.landing_term' } defaultMessage={ 'How much web development cost?' } /></strong></p>
                                    <ul className="pl-0">
                                        <FormattedHTMLMessage id={ 'prices.landing_description' } defaultMessage={ 'How much web development cost?' } />
                                    </ul>
                                </div>
                                <button className="btn btn-primary pl-4 pr-4 mt-4 pl-sm-5 pr-sm-5 d-block mx-auto" type="submit"  onClick={() => {scroller.scrollTo('contactForm', { duration: 800, delay: 30, smooth: true }); this.props.selectProject(1)}}>
                                    <FormattedMessage id={ 'button.choose_project' } defaultMessage={ 'Choose project' } />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center mb-3 mb-lg-0">
                            <div className="row white-background p-md-4 p-3">
                                <LazyLoadImage className="price-item-img" alt="Landing page" src={Vehicle} />
                                <div className="col-12 price-item-header text-center py-3">
                                    <FormattedHTMLMessage id={ 'prices.website' } defaultMessage={ 'How much web development cost?' } />
                                </div>
                                <div className="col-12 price-number-div text-center mb-4">
                                    <FormattedMessage id={ 'prices.from' } defaultMessage={ 'How much web development cost?' } /> <span className="price-number">700 &euro;</span>
                                </div>
                                <div className="price-body text-left">
                                    <p>
                                        <FormattedMessage id={ 'prices.website_text' } defaultMessage={ 'How much web development cost?' } />
                                    </p>
                                    <p><strong><FormattedMessage id={ 'prices.website_term' } defaultMessage={ 'How much web development cost?' } /></strong></p>
                                    <ul className="pl-0">
                                        <FormattedHTMLMessage id={ 'prices.website_description' } defaultMessage={ 'How much web development cost?' } />
                                    </ul>
                                </div>
                                <button className="btn btn-primary pl-4 pr-4 mt-4 pl-sm-5 pr-sm-5 d-block mx-auto" type="submit"  onClick={() => {scroller.scrollTo('contactForm', { duration: 800, delay: 30, smooth: true }); this.props.selectProject(2)}}>
                                    <FormattedMessage id={ 'button.choose_project' } defaultMessage={ 'Выбрать проект' } />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center mb-3 mb-lg-0">
                            <div className="row white-background p-md-4 p-3">
                                <LazyLoadImage className="price-item-img" alt="Landing page" src={Airplane} />
                                <div className="col-12 price-item-header text-center py-3">
                                    <FormattedHTMLMessage id={ 'prices.shop' } defaultMessage={ 'How much web development cost?' } />
                                </div>
                                <div className="col-12 price-number-div text-center mb-4">
                                    <FormattedMessage id={ 'prices.from' } defaultMessage={ 'How much web development cost?' } /> <span className="price-number">600 &euro;</span>
                                </div>
                                <div className="price-body text-left">
                                    <p>
                                        <FormattedMessage id={ 'prices.shop_text' } defaultMessage={ 'How much web development cost?' } />
                                    </p>
                                    <p><strong><FormattedMessage id={ 'prices.shop_term' } defaultMessage={ 'How much web development cost?' } /></strong></p>
                                    <ul className="pl-0">
                                        <FormattedHTMLMessage id={ 'prices.shop_description' } defaultMessage={ 'How much web development cost?' } />
                                    </ul>
                                </div>
                                <button className="btn btn-primary pl-4 pr-4 mt-4 pl-sm-5 pr-sm-5 d-block mx-auto" type="submit"  onClick={() => {scroller.scrollTo('contactForm', { duration: 800, delay: 30, smooth: true }); this.props.selectProject(3)}}>
                                    <FormattedMessage id={ 'button.choose_project' } defaultMessage={ 'Выбрать проект' } />
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="row white-background p-md-4 p-3">
                                <LazyLoadImage className="price-item-img" alt="Landing page" src={Ship} />
                                <div className="col-12 price-item-header text-center py-3">
                                    <FormattedHTMLMessage id={ 'prices.webapp' } defaultMessage={ 'How much web development cost?' } />
                                </div>
                                <div className="col-12 price-number-div text-center mb-4">
                                    <FormattedMessage id={ 'prices.from' } defaultMessage={ 'How much web development cost?' } /> <span className="price-number">100 &euro;</span>
                                </div>
                                <div className="price-body text-left">
                                    <p>
                                        <FormattedMessage id={ 'prices.webapp_text' } defaultMessage={ 'How much web development cost?' } />
                                    </p>
                                    <p><strong><FormattedMessage id={ 'prices.webapp_term' } defaultMessage={ 'How much web development cost?' } /></strong></p>
                                    <ul className="pl-0">
                                        <FormattedHTMLMessage id={ 'prices.webapp_description' } defaultMessage={ 'How much web development cost?' } />
                                    </ul>
                                </div>
                                <button className="btn btn-primary pl-4 pr-4 mt-4 pl-sm-5 pr-sm-5 d-block mx-auto" type="submit" onClick={() => {scroller.scrollTo('contactForm', { duration: 800, delay: 30, smooth: true }); this.props.selectProject(4)}}>
                                    <FormattedMessage id={ 'button.choose_project' } defaultMessage={ 'Выбрать проект' } />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    };
}

function mapDispatchToProps(dispatch) {
    return({
        selectProject: (data) => {
            dispatch(userActions.selectProject(data))
        }
    })
}

const connectedPrices = connect(mapStateToProps, mapDispatchToProps)(Prices);
export { connectedPrices as Prices };
