import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './_library';
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from './_library/connectedIntlProvider';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import lvLocaleData from 'react-intl/locale-data/lv';
import ruLocaleData from 'react-intl/locale-data/ru';

addLocaleData([...enLocaleData, ...lvLocaleData, ...ruLocaleData]);

render(
    <Provider store={store}>
        <IntlProvider>
          <Router>
            <App />
          </Router>
        </IntlProvider>
    </Provider>
  ,
  document.getElementById('root')
);
