import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import AdvantageImg from '../assets/img/advantage-img.png'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Fast from '../assets/img/icons/advantages/fast.png';
import Comfort from '../assets/img/icons/advantages/comfort.png';
import Responsive from '../assets/img/icons/advantages/responsive.png';
import Safe from '../assets/img/icons/advantages/safe.png';
import Secure from '../assets/img/icons/advantages/secure.png';
import Unique from '../assets/img/icons/advantages/unique.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { scroller } from "react-scroll/modules";

export class Advantages extends Component {

    render() {


        return (
            <section className="yellow-background">
                <div className="container-fluid text-center">
                    <h2>
                        <FormattedHTMLMessage id={ 'advantages.heading' } defaultMessage={ 'Web application' } />
                    </h2>
                    <div className="row">
                        <div className="col-md-6 order-md-0 order-1">
                            <h3 className="text-left mb-2 ml-2">
                                <FormattedHTMLMessage id={ 'advantages.subheading' } defaultMessage={ '6 advantages' } />
                            </h3>
                            <div className="row text-left">
                                <div className="col-md-6">
                                    <div className="advantage d-flex">
                                        <div className="icon my-auto">
                                            <img src={ Fast } alt="advantage" />
                                        </div>
                                        <div className="text pl-3 my-auto">
                                            <FormattedMessage id={ 'advantages.advantage1' } defaultMessage={ 'Fast' } />
                                            <span className="black-color"><FormattedMessage id={ 'advantages.advantage_description1' } defaultMessage={ 'Fast' } /></span>
                                        </div>
                                    </div>
                                    <div className="advantage d-flex">
                                        <div className="icon my-auto">
                                            <img src={ Secure } alt="advantage" />
                                        </div>
                                        <div className="text pl-3 my-auto">
                                            <FormattedMessage id={ 'advantages.advantage2' } defaultMessage={ 'Secure' } />
                                            <span className="black-color"><FormattedMessage id={ 'advantages.advantage_description2' } defaultMessage={ 'Secure' } /></span>

                                        </div>
                                    </div>
                                    <div className="advantage d-flex">
                                        <div className="icon my-auto">
                                            <img src={ Safe } alt="advantage" />
                                        </div>
                                        <div className="text pl-3 my-auto">
                                            <FormattedMessage id={ 'advantages.advantage3' } defaultMessage={ 'Safe' } />
                                            <span className="black-color"><FormattedMessage id={ 'advantages.advantage_description3' } defaultMessage={ 'Safe' } /></span>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="advantage d-flex">
                                        <div className="icon my-auto">
                                            <img src={ Comfort } alt="advantage" />
                                        </div>
                                        <div className="text pl-3 my-auto">
                                            <FormattedMessage id={ 'advantages.advantage4' } defaultMessage={ 'Comfort' } />
                                            <span className="black-color"><FormattedMessage id={ 'advantages.advantage_description4' } defaultMessage={ 'Comfort' } /></span>

                                        </div>
                                    </div>
                                    <div className="advantage d-flex">
                                        <div className="icon my-auto">
                                            <img src={ Responsive } alt="advantage" />
                                        </div>
                                        <div className="text pl-3 my-auto">
                                            <FormattedMessage id={ 'advantages.advantage5' } defaultMessage={ 'Responsive' } />
                                            <span className="black-color"><FormattedMessage id={ 'advantages.advantage_description5' } defaultMessage={ 'Responsive' } /></span>

                                        </div>
                                    </div>
                                    <div className="advantage d-flex">
                                        <div className="icon my-auto">
                                            <img src={ Unique } alt="advantage" />
                                        </div>
                                        <div className="text pl-3 my-auto">
                                            <FormattedMessage id={ 'advantages.advantage6' } defaultMessage={ 'Unique' } />
                                            <span className="black-color"><FormattedMessage id={ 'advantages.advantage_description6' } defaultMessage={ 'Unique' } /></span>

                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary dark-button ml-2 pl-5 pr-5 mt-4 d-block" type="submit" onClick={() => scroller.scrollTo('contactFormBig', { duration: 800, delay: 30, smooth: true })}>
                                    <FormattedMessage id={ 'button.apply' } defaultMessage={ 'Заявка на создание' } />
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-1 order-0">
                            <img className="w-100" alt="Our advantages" src={AdvantageImg} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
