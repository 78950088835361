import { userConstants } from '../_constants';


export const userActions = {
    selectProject
};

function selectProject(data) {
    return dispatch => {
        dispatch((data => {
            return { type: userConstants.SELECT_PROJECT, data }
        })(data));
    };
}