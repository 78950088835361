import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

export class AboutUs extends Component {

    constructor() {
        super();

        this.state = {
            activeId: 1
        };
    }

    render() {

        return (
            <section className="pb-0 pt-2">
                <div className="row">
                    <div className="col-lg-5 col-md-6 dark-background-without-brush p-lg-5 p-md-3 p-2">
                        <div className="row">
                            <div className="col-12">
                                <h2>
                                    <div className="row">
                                        <div className="number d-inline">9</div>
                                        <div className="about-us-header ml-4 d-inline">
                                            <FormattedHTMLMessage id={ 'aboutus.heading' } defaultMessage={ 'facts about<br> us' } />
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className={this.state.activeId === 1 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 1 })}>
                                <div className="about-us-number">1</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label1' } defaultMessage={ 'Experience' } /></div>
                            </div>
                            <div className={this.state.activeId === 2 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 2 })}>
                                <div className="about-us-number">2</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label2' } defaultMessage={ 'Team' } /></div>
                            </div>
                            <div className={this.state.activeId === 3 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 3 })}>
                                <div className="about-us-number">3</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label3' } defaultMessage={ 'Quality' } /></div>
                            </div>
                            <div className={this.state.activeId === 4 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 4 })}>
                                <div className="about-us-number">4</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label4' } defaultMessage={ 'Term' } /></div>
                            </div>
                            <div className={this.state.activeId === 5 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 5 })}>
                                <div className="about-us-number">5</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label5' } defaultMessage={ 'Approach' } /></div>
                            </div>
                            <div className={this.state.activeId === 6 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 6 })}>
                                <div className="about-us-number">6</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label6' } defaultMessage={ 'Technology' } /></div>
                            </div>
                            <div className={this.state.activeId === 7 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 7 })}>
                                <div className="about-us-number">7</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label7' } defaultMessage={ 'Speed' } /></div>
                            </div>
                            <div className={this.state.activeId === 8 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 8 })}>
                                <div className="about-us-number">8</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label8' } defaultMessage={ 'Warranty' } /></div>
                            </div>
                            <div className={this.state.activeId === 9 ? "col-lg-4 col-6 text-center about-us-item active pb-2": "col-lg-4 col-6 text-center about-us-item pb-2"}
                                 onClick={() => this.setState({ activeId: 9 })}>
                                <div className="about-us-number">9</div>
                                <div className="about-us-name"><FormattedMessage id={ 'aboutus.label9' } defaultMessage={ 'Management' } /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 about-us-text-block">
                        {this.state.activeId === 1 &&
                            <div>
                                <span className="about-us-text-number">1</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label1' } defaultMessage={ 'Experience' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading1' } defaultMessage={ 'Our experience helps us find innovative solutions and solve complex problems.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text1' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 2 &&
                            <div>
                                <span className="about-us-text-number">2</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label2' } defaultMessage={ 'Team' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading2' } defaultMessage={ 'Purposeful and reliable team, which is ready to complete the task under any conditions' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text2' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 3 &&
                            <div>
                                <span className="about-us-text-number">3</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label3' } defaultMessage={ 'Quality' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading3' } defaultMessage={ 'We create reliable applications that are pleasant to use.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text3' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 4 &&
                            <div>
                                <span className="about-us-text-number">4</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label4' } defaultMessage={ 'Term' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading4' } defaultMessage={ 'In business, it’s sometimes important to be first, so you can’t delay.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text4' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 5 &&
                            <div>
                                <span className="about-us-text-number">5</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label5' } defaultMessage={ 'Approach' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading5' } defaultMessage={ 'We have an authorial approach to the development of each system.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text5' } defaultMessage={ 'Management' } /></span>
                                </div>
                        </div>
                        }
                        {this.state.activeId === 6 &&
                            <div>
                                <span className="about-us-text-number">6</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label6' } defaultMessage={ 'Technology' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading6' } defaultMessage={ 'The IT industry is growing every day.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text6' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 7 &&
                            <div>
                                <span className="about-us-text-number">7</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label7' } defaultMessage={ 'Speed' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading7' } defaultMessage={ 'Website visitors will not wait longer than 5 seconds, they will rather switch to a faster one.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text7' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 8 &&
                            <div>
                                <span className="about-us-text-number">8</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label8' } defaultMessage={ 'Warranty' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading8' } defaultMessage={ 'We take responsibility for the written code' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text8' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                        {this.state.activeId === 9 &&
                            <div>
                                <span className="about-us-text-number">9</span>
                                <div className="row p-md-5 p-2">
                                    <div className="about-us-text-heading"><FormattedMessage id={ 'aboutus.label9' } defaultMessage={ 'Management' } /></div>
                                    <div className="about-us-text-subheading mb-3"><FormattedMessage id={ 'aboutus.description_subheading9' } defaultMessage={ 'We use transparent management, where the client, if desired, can himself monitor what tasks are being completed and have already been completed.' } /></div>
                                    <span className="about-us-text"><FormattedHTMLMessage id={ 'aboutus.description_text9' } defaultMessage={ 'Management' } /></span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}
