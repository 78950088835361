import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import QuestionsImageLV from '../assets/img/questions_lv.png'
import QuestionsImageEN from '../assets/img/questions_en.png'
import QuestionsImageRU from '../assets/img/questions_ru.png'
import { FormattedHTMLMessage } from "react-intl";
import connect from "react-redux/es/connect/connect";


class Questions extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        const {lang} = this.props.i18n;

        return (
            <section className="white-background">
                <div className="container-fluid text-center">
                    <h2>
                        <FormattedHTMLMessage id={ 'questions.heading' } defaultMessage={ 'How much web development cost?' } />
                    </h2>
                    <div className="row no-gutters mx-auto">
                        <div className="offset-lg-2 col-lg-8 col-12">
                            {lang === "en" &&
                                <img className="questions-img" alt="Customers problems" src={QuestionsImageEN}/>
                            }
                            {lang === "ru" &&
                                <img className="questions-img" alt="Customers problems" src={QuestionsImageRU}/>
                            }
                            {lang === "lv" &&
                                <img className="questions-img" alt="Customers problems" src={QuestionsImageLV}/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


function mapStateToProps(state) {
    const { i18n } = state;
    return {
        i18n
    };
}

const connectedQuestions = connect(mapStateToProps)(Questions);
export { connectedQuestions as Questions };
