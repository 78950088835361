import React, { Component } from 'react';
import '../assets/styles/Header.css';
import micronLogo from '../assets/img/micron_logo_full_small.png';
import { connect } from 'react-redux';
import { i18nActions } from '../_actions';


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdownShown: false
        };
    }

    render() {

        const { i18n } = this.props;
        const { dropdownShown } = this.state;

        return(
          <div className="pt-4 container-fluid header px-md-5 px-2">
              <div className="row">
                  <div className="col-6 col-md-4 px-0 text-center text-md-left d-none d-md-block">
                      <div className="pl-2">
                          <span className="d-inline-block responsive-text">
                              <a className="phone" href="tel:+37120382837">+371 20382837</a><br></br>
                              <a className="phone" href="mailto:info@micron.lv">info@micron.lv</a>
                          </span>
                      </div>
                  </div>
                  <div className="col-3 col-md-4 text-center p-0">
                      <img className="header-logo" alt="Micron logo" src={micronLogo} />
                  </div>
                  <div className="col-6 px-0 text-center text-md-left d-block d-md-none">
                      <div className="pl-lg-3 pl-2">
                          <span className="d-inline-block responsive-text">
                              <a className="phone" href="tel:+37120382837">+371 20382837</a><br></br>
                              <a className="phone" href="mailto:info@micron.lv">info@micron.lv</a>
                          </span>
                      </div>
                  </div>
                  <div className="col-3 col-md-4 pt-lg-3 text-right pl-0">
                      <a className="dropdown-item nav-link d-inline" href="/ru">RU</a>
                      {/*<div className="dropdown float-right header-lang pr-lg-3 responsive-text">*/}
                      {/*<button className="btn btn-link p-0 dropdown-toggle" type="button"*/}
                      {/*onClick={() => this.setState(prevState => ({dropdownShown: !prevState.dropdownShown}))}>*/}
                      {/*{i18n.lang}*/}

                      {/*</button>*/}
                      {/*<div className="dropdown-menu">*/}
                      {/*<a className="dropdown-item nav-link" href="javascript:void(0)" onClick={() => this.props.changeLang('en')}>EN</a>*/}
                      {/*<a className="dropdown-item nav-link" href="javascript:void(0)" onClick={() => this.props.changeLang('lv')}>LV</a>*/}
                      {/*<a className="dropdown-item nav-link" href="/lv">LV</a>*/}
                      {/*</div>*/}
                      {/*</div>*/}
                  </div>
              </div>
          </div>
        )
  }
}

function mapStateToProps(state) {
    const { i18n } = state;
    return {
        i18n
    };
}

function mapDispatchToProps(dispatch) {
    return({
        changeLang: (lang) => {
            dispatch(i18nActions.changeLanguage(lang))
        }
    })
}

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export { connectedHeader as Header };