import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import FlipFulLogo from '../assets/img/reviews/flipful-Logo.png';
import TeirautoLogo from '../assets/img/reviews/teirauto-Logo.png';
import SmartwillLogo from '../assets/img/reviews/smartwill-logo.png';
import MicronLogo from '../assets/img/micron_logo_full_small.png';
import LeftQuoteBlack from '../assets/img/icons/left-quote-black.png';
import RightQuoteBlack from '../assets/img/icons/right-quote-black.png';
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

export class Reviews extends Component {

    constructor() {
        super();

        this.state = {
            activeId: 1
        };
    }

    render() {
        return (
            <section className="position-relative dark-background">
                <div className="container-fluid text-center">
                    <h2>
                        <FormattedHTMLMessage id={ 'reviews.heading' } defaultMessage={ 'Reviews' } />
                    </h2>
                    <div className="row">
                        <div className="tabs-head mb-5 mx-auto w-100">
                            <div className="row">
                                <div className={this.state.activeId === 1 ? "col item-head active p-0": "col item-head p-0"}
                                     onClick={() => this.setState({ activeId: 1 })}>
                                    <div id="id1" className="tabs tab-head">
                                        <div className="name">
                                            Flipful
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.activeId === 2 ? "col item-head active p-0": "col item-head p-0"}
                                     onClick={() => this.setState({ activeId: 2 })}>
                                    <div id="id2" className="tabs tab-head">
                                        <div className="name">
                                            Smartwill
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.activeId === 3 ? "col item-head active p-0": "col item-head p-0"}
                                     onClick={() => this.setState({ activeId: 3 })}>
                                    <div id="id3" className="tabs tab-head">
                                        <div className="name">
                                            Teirauto
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 offset-md-2 mt-5 p-2">
                            {this.state.activeId === 1 &&
                                <div className="row review">
                                    <div className="col-md-4 my-auto">
                                        <img className="review-logo p-md-0 p-5" src={FlipFulLogo} alt="Flipful logo"/>
                                    </div>
                                    <div className="col-md-8 p-md-5 p-0">
                                        <div className="flipful-review review-div position-relative text-left p-3">
                                            <h3 className="mx-auto"><FormattedMessage id={ 'review.subheading1' } defaultMessage={ 'Flipful' } /></h3>
                                            <div className="position-relative">
                                                <p className="position-absolute review-text">
                                                    <img className="position-absolute review-quote review-quote-top" alt="quote" src={LeftQuoteBlack}/>
                                                    <FormattedMessage id={ 'review.text1' } defaultMessage={ 'Flipful' } />
                                                    <img className="position-absolute review-quote review-quote-bottom" alt="quote" src={RightQuoteBlack}/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-12">
                                            <img src={MicronLogo} className="review-micron-logo m-2" alt="Micron logo"/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.activeId === 2 &&
                                <div className="row review">
                                    <div className="col-md-4 my-auto">
                                        <img className="review-logo p-md-0 p-5" src={SmartwillLogo} alt="Smartwill logo"/>
                                    </div>
                                    <div className="col-md-8 p-md-5 p-0">
                                        <div className="smartwill-review review-div position-relative text-left p-3">
                                            <h3 className="mx-auto"><FormattedMessage id={ 'review.subheading2' } defaultMessage={ 'Smartwill' } /></h3>
                                            <div className="position-relative">
                                                <p className="position-absolute review-text">
                                                    <img className="position-absolute review-quote review-quote-top" alt="quote" src={LeftQuoteBlack}/>
                                                    <FormattedMessage id={ 'review.text2' } defaultMessage={ 'Smartwill' } />
                                                    <img className="position-absolute review-quote review-quote-bottom" alt="quote" src={RightQuoteBlack}/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-12">
                                            <img src={MicronLogo} className="review-micron-logo m-2" alt="Micron logo"/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.activeId === 3 &&
                                <div className="row review">
                                    <div className="col-md-4 my-auto">
                                        <img className="review-logo p-md-0 p-5" src={TeirautoLogo} alt="Teirauto logo"/>
                                    </div>
                                    <div className="col-md-8 p-md-5 p-0">
                                        <div className="teirauto-review review-div position-relative text-left p-3">
                                            <h3 className="mx-auto"><FormattedMessage id={ 'review.subheading3' } defaultMessage={ 'Teirauto' } /></h3>
                                            <div className="position-relative">
                                            <p className="position-absolute review-text">
                                                <img className="position-absolute review-quote review-quote-top" alt="quote" src={LeftQuoteBlack}/>
                                                <FormattedHTMLMessage id={ 'review.text3' } defaultMessage={ 'Teirauto' } />
                                                <img className="position-absolute review-quote review-quote-bottom" alt="quote" src={RightQuoteBlack}/>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-12">
                                            <img src={MicronLogo} className="review-micron-logo m-2" alt="Micron logo"/>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
