import React, { Component } from 'react';
import './assets/styles/bootstrap.min.css';
import { Header } from './components/Header';
import Footer from './components/Footer';
import { ContactForm } from './components/ContactForm';
import { Index } from './components/index';
import { Questions } from './components/Questions';
import { Advantages } from './components/Advantages';
import { Reviews } from './components/Reviews';
import { ContactFormBig } from './components/ContactFormBig';
import { AboutUs } from './components/AboutUs';
import { Portfolio } from './components/Portfolio';
import { Prices } from './components/Prices';
import { Team } from './components/Team';


class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
        <div className="App">
            <Header />
            <Index />
            <Questions />
            <Advantages />
            <Reviews />
            <ContactFormBig />
            <AboutUs />
            <Portfolio />
            <Prices />
            <ContactForm />
            <Team />
            <Footer />
        </div>
        );
    }
}

export default App;
