import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import Ivan from '../assets/img/ivan.png'
import Michael from '../assets/img/michael.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

export class Team extends Component {
    render() {
        return (
            <section className="position-relative dark-background team-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 d-md-block d-none">
                            <div className="team-name p-md-5 p-0 right text-right">
                                Michael Levenciks<br></br>
                                CTO<br></br>
                                <a href="mailto:mihails.levenciks@micron.lv">mihails.levenciks@micron.lv</a><br></br>
                                <a href="tel:+37122350367">+371 22350367</a><br></br>
                                <a className="social-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/micronlv">
                                    <div className="fa fa-linkedin-square float-right" aria-hidden="true" />
                                </a>
                            </div>
                            <LazyLoadImage className="team-img" alt="Mihails Levenchiks" src={Michael} />
                        </div>
                        <div className="col-md-6">
                            <div className="team-name p-md-5 p-0">
                                Ivan Dorohov<br></br>
                                CEO<br></br>
                                <a href="mailto:ivans.dorohovs@micron.lv">ivans.dorohovs@micron.lv</a><br></br>
                                <a href="tel:+37120382837">+371 20382837</a><br></br>
                                <a className="social-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/micronlv">
                                    <div className="fa fa-linkedin-square" aria-hidden="true" />
                                </a>
                            </div>
                            <LazyLoadImage className="team-img float-right" alt="Ivan Dorohov" src={Ivan} />
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}
