import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import connect from "react-redux/es/connect/connect";
import { scroller } from "react-scroll/modules";


class Portfolio extends Component{

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        const { lang } = this.props.i18n;

        return (
            <section className="yellow-background">
                <div className="text-center">
                    <h2>
                        <span className="highlight"><FormattedMessage id={'portfolio.heading'}
                                                                      defaultMessage={'Portfolio'}/></span>
                    </h2>
                </div>
                <div className="row">
                    <div className="col-12">

                        <div className="row">
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="SmartLaws" className="w-100" src={require("../assets/img/portfolio/" + lang + "/1.png")}/>
                            </div>
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="Smartwill" className="w-100" src={require("../assets/img/portfolio/" + lang + "/2.png")}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="Teirauto" className="w-100" src={require("../assets/img/portfolio/" + lang + "/5.png")}/>
                            </div>
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="Appeix" className="w-100" src={require("../assets/img/portfolio/" + lang + "/4.png")}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="finerium" className="w-100" src={require("../assets/img/portfolio/" + lang + "/3.png")}/>
                            </div>
                            <div className="col-md-6  mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="Black rose" className="w-100" src={require("../assets/img/portfolio/" + lang + "/9.png")}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="Protocol finance" className="w-100" src={require("../assets/img/portfolio/" + lang + "/8.png")}/>
                            </div>
                            <div className="col-md-6 mb-4 p-md-3 p-0">
                                <LazyLoadImage alt="Karlovske zlato" className="w-100" src={require("../assets/img/portfolio/" + lang + "/6.png")}/>
                            </div>
                        </div>
                        <div className="row">
                            <button className="btn btn-primary dark-button ml-2 pl-5 pr-5 mt-4 mx-auto" type="submit"  onClick={() => scroller.scrollTo('contactForm', { duration: 800, delay: 30, smooth: true })}>
                                <FormattedMessage id={'button.order'} defaultMessage={'Make an order'}/>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const { i18n } = state;
    return {
        i18n
    };
}

const connectedPortfolio = connect(mapStateToProps)(Portfolio);
export { connectedPortfolio as Portfolio };
