import { userConstants } from '../_constants';


const initialState = {
    selectProject: 0
};

export function user(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case userConstants.SELECT_PROJECT:
            state.selectProject = action.data;
            console.log(state.selectProject);
            return Object.assign({}, state);
        default:
            return state;
    }
}