import React, { Component } from 'react';
import '../App.css';
import Logo from '../assets/img/micron_logo_full_small.png'
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";


export default class Footer extends Component {
  render() {
    return(
        <section className="white-background">
            <div className="footer text-center mt-5 mb-4">
                <div className="row mb-3 order-md-1 order-1">
                    <div className="col-md-4">
                        <dl className="contact-info" itemProp="telephone">
                            <dt><FormattedMessage id={ 'footer.phone' } defaultMessage={ 'Phone number' } /></dt>
                            <dd><a href="tel:+37120382837">+371 20382837</a></dd>
                        </dl>
                    </div>
                    <div className="col-md-4 order-md-2 order-3">
                        <div className="logo">
                            <a href="https://www.micron.lv"><img src={Logo} alt="Micron logo" /></a>
                        </div>
                        <div className="descriptor">
                            <FormattedHTMLMessage id={ 'footer.heading' } defaultMessage={ 'Software development agency' } />
                        </div>
                        <div className="mr-lg-3 mt-3 ">
                            <a className="mr-3 social-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/micronlv">
                                <div className="fa fa-linkedin-square d-inline-block" aria-hidden="true" />
                            </a>
                            <a className="mr-3 social-link" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/micron_lv/">
                                <div className="fa fa-instagram-square d-inline-block" aria-hidden="true" />
                            </a>
                            <a target="_blank" className="social-link" rel="noopener noreferrer" href="https://www.facebook.com/MicronLatvia/">
                                <div className="fa fa-facebook-square d-inline-block" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 order-md-3 order-2">
                        <dl className="contact-info" itemProp="telephone">
                            <dt><FormattedMessage id={ 'footer.email' } defaultMessage={ 'Email' } /></dt>
                            <dd><a href="mailto:info@micron.lv">info@micron.lv</a></dd>
                        </dl>
                    </div>
                </div>
                <span className="mr-sm-1 d-block d-sm-inline-block">Designed and developed by Micron.</span>
                <span className="d-block d-sm-inline-block">&copy; {new Date().getFullYear()} MICRON LTD. All rights reserved.</span>
            </div>
        </section>
    )
  }
}