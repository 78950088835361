import localeData from '../translations/i18n.json';
import { i18nConstants } from '../_constants';

const language = (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

const initialState = {
    lang: 'lv',
    messages: localeData[languageWithoutRegionCode] || localeData[language] || localeData.lv
};

export function i18n(state = Object.assign({}, initialState), action) {
    switch (action.type) {
        case i18nConstants.CHANGE_LANGUAGE:
            state.lang = action.data.lang;
            state.messages = localeData[action.data.lang];
            return Object.assign({}, state);
        default:
            return state;
    }
}