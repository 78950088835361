import React, { Component } from 'react';
import '../App.css';
import '../assets/styles/index.css';
import { ErrorMessage, Field, Form, Formik, getIn } from "formik";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { scroller } from 'react-scroll';
import * as Yup from "yup";


export class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formSucces: false,
            error: ''
        };

        this.offsetTop = 0;

        this.validationSchema = Yup.object().shape(
            {
                email: Yup.string()
                    .email(<FormattedMessage id={ 'contact.form.invalid_email' } defaultMessage={ 'Invalid email' } />)
                    .required(<FormattedMessage id={ 'contact.form.email_required' } defaultMessage={ 'Email required' } />)
                    .max(255, <FormattedMessage id={ 'contact.form.email_max_length' } defaultMessage={ 'Email length must be less than 255 symbols' } />),
                name: Yup.string()
                    .required(<FormattedMessage id={ 'contact.form.name_required' } defaultMessage={ 'Name required' } />)
                    .max(50, <FormattedMessage id={ 'contact.form.name_max_length' } defaultMessage={ 'Name length must be less than 50 symbols' } />),
                message: Yup.string()
                    .max(300, <FormattedMessage id={ 'contact.form.message_max_length' } defaultMessage={ 'Message length must be less than 300 symbols' } />)
            }
        );

        this.handleSubmit = this.handleSubmit.bind(this);
        this.getStyles = this.getStyles.bind(this);
    }

    getStyles(errors, fieldName) {
        if (getIn(errors, fieldName)) {
            return {
                border: '1px solid red'
            }
        }
    }

    handleSubmit(values, actions) {

        actions.setSubmitting(true);

        fetch('https://api.micron.lv',
            {
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({data: values})
            })
            .then(response => response.json())
            .then(result => {
                actions.setSubmitting(false);
                actions.resetForm();
                this.setState({formSucces: true});
            })
            .catch(e => {
                this.setState({error: <FormattedMessage id={ 'contact.form.error' } defaultMessage={ 'Error occurred. Please try again later.' } />});
                actions.setSubmitting(false);
            });
    }

    contactForm() {
        return(
            <Formik
                enableReinitialize={true}
                initialValues={{email: '', name: '', message: ''}}
                validationSchema={this.validationSchema}
                onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                render={({errors, isSubmitting}) => (
                    <Form className="mt-4" id="contact-form">
                        <div className="row no-gutters">
                            <div className="">
                                <label className="w-100">
                                    <FormattedMessage id={ 'contact.form.placeholder.name' } defaultMessage={ 'Name *' } />
                                    <Field style={this.getStyles(errors, 'example')}  className="form-control mt-2" type="text" name="name" />
                                </label>
                                <ErrorMessage className="error-message" name="name" component="div" />
                                <label className="w-100">
                                    <FormattedMessage id={ 'contact.form.placeholder.email' } defaultMessage={ 'Email *' } />
                                    <Field className="form-control mt-2" type="text" name="email" />
                                </label>
                                <ErrorMessage className="error-message" name="email" component="div" />
                                <label className="w-100">
                                    <FormattedMessage id={ 'contact.form.placeholder.message' } defaultMessage={ 'Message' } />
                                    <Field className="form-control mt-2" type="text" component="textarea" name="message"/>
                                </label>
                                <ErrorMessage className="error-message" name="message" component="div" />
                            </div>
                            <p className="form-confirm-privacy"><FormattedHTMLMessage id={ 'contact.form.policy' } defaultMessage={ 'Policy' } /></p>
                        </div>
                        <button className="btn btn-primary pl-4 pr-4 mt-4 pl-sm-5 pr-sm-5 d-block mx-auto" type="submit" disabled={isSubmitting}>
                            <FormattedMessage id={ 'button.get_estimate' } defaultMessage={ 'Get estimate' } />
                        </button>
                    </Form>
                )}
            />
        );
    }

    render() {
        return (
            <React.Fragment>
                <div className="position-relative index mt-5">
                    <div className="row pl-md-5 pr-md-5 pl-2 pr-2 pt-3 pt-md-5">
                        <div className="col-lg-7 heading text-center">
                            <h1 className="mt-0 pt-3 mt-md-5 mt-lg-4 my-md-0 my-xl-4 my-5">
                                <FormattedHTMLMessage id={ 'index.heading' } defaultMessage={ 'Web development for business' } />
                            </h1>
                            <button className="btn btn-primary" onClick={() => scroller.scrollTo('contactFormBig', { duration: 800, delay: 10, smooth: true })}>
                                <FormattedMessage id={ 'button.order' } defaultMessage={ 'Make an order' } />
                            </button>
                        </div>
                        <div className="col-lg-5 d-none d-lg-block">
                            <div className="row">
                                <div className="col-lg-10 col-md-11 offset-md-1 contact-form-small">
                                    <h3 className="mb-2">
                                        <FormattedHTMLMessage id={ 'index.form.heading' } defaultMessage={ 'Get free estimate' } />
                                    </h3>
                                    {this.state.formSucces &&
                                        <p className="text-success"><FormattedMessage id={ 'form.success' } defaultMessage={ 'Your message is sent!' } /></p>
                                    }
                                    {this.contactForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="row no-gutters dark-background p-3">
                        <div className="index-text col-md-8 col-lg-8 col-12 mx-auto">
                            <FormattedHTMLMessage id={ 'index.text' } defaultMessage={ 'Get free estimate' } />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}