import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ReactDOM from 'react-dom';
import '../assets/styles/ContactForm.css';
import { Element } from 'react-scroll';


export class ContactFormBig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            successMessage: false,
            error: ''
        };

        this.offsetTop = 0;

        this.validationSchema = Yup.object().shape(
            {
                email: Yup.string()
                    .email(<FormattedMessage id={ 'contact.form.invalid_email' } defaultMessage={ 'Invalid email' } />)
                    .required(<FormattedMessage id={ 'contact.form.email_required' } defaultMessage={ 'Email required' } />)
                    .max(255, <FormattedMessage id={ 'contact.form.email_max_length' } defaultMessage={ 'Email length must be less than 255 symbols' } />),
                name: Yup.string()
                    .required(<FormattedMessage id={ 'contact.form.name_required' } defaultMessage={ 'Name required' } />)
                    .max(50, <FormattedMessage id={ 'contact.form.name_max_length' } defaultMessage={ 'Name length must be less than 50 symbols' } />),
                phone: Yup.string()
                    .max(50, <FormattedMessage id={ 'contact.form.phone_max_length' } defaultMessage={ 'Phone length must be less than 50 symbols' } />),
                message: Yup.string()
                    .max(300, <FormattedMessage id={ 'contact.form.message_max_length' } defaultMessage={ 'Message length must be less than 300 symbols' } />)
            }
        );

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values, actions) {

        actions.setSubmitting(true);

        fetch('https://api.micron.lv',
            {
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({data: values})
            })
            .then(response => response.json())
            .then(result => {
                actions.setSubmitting(false);
                actions.resetForm();
                this.setState({successMessage: true});
            })
            .catch(e => {
                this.setState({error: <FormattedMessage id={ 'contact.form.error' } defaultMessage={ 'Error occurred. Please try again later.' } />});
                actions.setSubmitting(false);
            });
    }

    componentDidMount() {
        // this.offsetTop = ReactDOM.findDOMNode(this).offsetTop;
    }

    render() {

        const { successMessage, error } = this.state;

        return(
            <section className="white-background pb-0 pt-5">
                <div className="container-fluid">
                    <div className="text-center">
                        <Element name="contactFormBig" />
                        <h2 className="mb-2">
                            <FormattedHTMLMessage id={ 'contact.form_big.heading' } defaultMessage={ 'order web system creation' } />
                        </h2>
                        <p>
                            <FormattedMessage id={ 'contact.form.text' } defaultMessage={ 'Please leave your contacts' } />
                        </p>
                        {successMessage &&
                            <div className="text-center mb-5 mt-3 text-success">
                                <FormattedMessage id={'contact.form.success'}
                                                  defaultMessage={'Your message received. We will contact you soon. Thank you!'}/>
                            </div>
                        }
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{email: '', name: '', phone: '', message: '', landing: false, website: false, store: false, application: false, crm:false, erp: false, cms: false, api: false, payment: false, other: false}}
                        validationSchema={this.validationSchema}
                        onSubmit={(values, actions) => this.handleSubmit(values, actions)}
                        render={({errors, isSubmitting}) => (
                            <Form className="mt-4" id="contact-form-big">
                                <div className="row">
                                    <div className="col-md-6 p-md-5 p-2">
                                        <h3>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.heading' } defaultMessage={ 'Choose project' } />
                                        </h3>
                                        <label className="project-type position-relative pl-5 w-100 mb-3">
                                            <Field type="checkbox" name="landing" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item1' } defaultMessage={ 'Landing' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100 mb-3">
                                            <Field type="checkbox" name="website" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item2' } defaultMessage={ 'Corporate website' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100 mb-3">
                                            <Field type="checkbox" name="store" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item3' } defaultMessage={ 'Online store' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="application" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item4' } defaultMessage={ 'Web application' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="crm" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item5' } defaultMessage={ 'CRM' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="erp" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item6' } defaultMessage={ 'ERP' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="cms" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item7' } defaultMessage={ 'CMS' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="api" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item8' } defaultMessage={ 'API integration' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="payment" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item9' } defaultMessage={ 'Payment system integration' } />
                                        </label>
                                        <label className="project-type position-relative pl-5 w-100">
                                            <Field type="checkbox" name="other" className="checkbox"/><span className="checkmark"></span>
                                            <FormattedMessage id={ 'contact.form.checkbox_list.item10' } defaultMessage={ 'Other' } />
                                        </label>
                                    </div>
                                    <div className="col-md-6 p-lg-5 p-2">
                                        {error &&
                                        <div className="text-center mb-2">{error}</div>
                                        }
                                        <div className="row no-gutters mt-md-0 mt-5">
                                            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 contact-form-big p-3 p-sm-5">
                                                <h3><FormattedMessage id={ 'contact.form.leave_contacts' } defaultMessage={ 'Leave your contacts' } /></h3>
                                                <label className="w-100">
                                                    <FormattedMessage id={ 'contact.form.placeholder.name' } defaultMessage={ 'Name *' } />
                                                    <Field className="form-control mt-2" type="text" name="name" />
                                                </label>
                                                <ErrorMessage className="error-message" name="name" component="div" />
                                                <label className="w-100">
                                                    <FormattedMessage id={ 'contact.form.placeholder.email' } defaultMessage={ 'Email *' } />
                                                    <Field className="form-control mt-2" type="text" name="email"/>
                                                </label>
                                                <ErrorMessage className="error-message" name="email" component="div" />
                                                <label className="w-100">
                                                    <FormattedMessage id={ 'contact.form.placeholder.phone' } defaultMessage={ 'Phone' } />
                                                    <Field className="form-control mt-2" type="text" name="phone" />
                                                </label>
                                                <ErrorMessage className="error-message" name="phone" component="div" />
                                                <label className="w-100">
                                                    <FormattedMessage id={ 'contact.form.placeholder.message' } defaultMessage={ 'Message' } />
                                                    <Field className="form-control mt-2" type="text" component="textarea" name="message"/>
                                                </label>
                                                <ErrorMessage className="error-message" name="message" component="div" />
                                                <p className="form-confirm-privacy"><FormattedHTMLMessage id={ 'contact.form.policy' } defaultMessage={ 'Policy' } /></p>
                                                <button className="btn btn-primary pl-4 pr-4 mt-4 pl-sm-5 pr-sm-5 d-block mx-auto" type="submit" disabled={isSubmitting}>
                                                    <FormattedMessage id={ 'button.get_estimate' } defaultMessage={ 'Get estimate' } />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    />
                </div>
            </section>
        );
    }

}